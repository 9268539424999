import { createI18n } from "vue-i18n";
import ru from "./locales/ru.json"
import en from "./locales/en.json"

export default createI18n({
    locale: localStorage.getItem('lang') || 'en', 
    fallbackLocale: 'ru', 
    legacy: false,
    globalInjection: true,
    messages: { 
        ru,
        en 
    },
    runtimeOnly: false,
})