<template>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">{{ $t("UI.email") }}</th>
                <th scope="col">{{ $t("UI.access") }}</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(elemStat, index) in listUsers" :key="elemStat.id">
                <td>{{ elemStat.mail }}</td>
                <td><span v-if="editRows != index || !isEditeble(index)">{{ elemStat.roles[0].title }}</span>
                    <select v-else class="form-select" v-model="editData.roles">
                        <option v-for="roleElem in listRoles" :value="roleElem.id">{{ roleElem.title }}</option>
                    </select>
                </td>
                <td>
                    <div class="d-flex">
                        <button class="border-0 text-decoration-none me-3 text-body-secondary" v-if="editRows != index"
                                :title="$t('UI.change')" @click="edit(index)"><font-awesome-icon
                                :icon="['fas', 'pen-to-square']" /></button>
                        <button class="border-0 text-decoration-none me-3 text-body-secondary" v-else
                            @click="saveIndex(index)" 
                                :title="$t('UI.save')"><font-awesome-icon
                                :icon="['fas', 'floppy-disk']" /></button>
                        <button class="border-0 text-decoration-none me-3 text-body-secondary" v-if="isEditeble(index)"
                            @click="deleteRole(elemStat.id)" 
                                :title="$t('UI.delete')"><font-awesome-icon
                                :icon="['fas', 'trash-can']" /></button>
                    </div>
                </td>
            </tr>
            <tr v-if="newData != null">
                <td><input class="form-control" v-model="newData.mail"></td>
                <td>
                    <select class="form-select" v-model="newData.role_id">
                        <option v-for="elemStat in listRoles" :value="elemStat.id">{{ elemStat.title }}</option>
                    </select>
                </td>
                <td>
                    <div class="d-flex">
                        <button class="border-0 text-decoration-none me-3 text-body-secondary" @click="saveNew()"
                            :title="$t('UI.save')"><font-awesome-icon :icon="['fas', 'floppy-disk']" /></button>
                        <button class="border-0 text-decoration-none me-3 text-body-secondary" @click="deleteNew()"
                            :title="$t('UI.delete')"><font-awesome-icon :icon="['fas', 'trash-can']" /></button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <button class="border-0 text-decoration-none me-3 text-body-secondary" @click="addNew()"
        :title="$t('UI.addRole')"><font-awesome-icon :icon="['fas', 'plus']" /></button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    props: ['info'],
    data() {
        return {
            listUsers: [],
            listRoles: [],
            newData: null,
            editRows: null,
            editData: {
                id: 0,
                mail: null,
                roles: null
            },
        }
    },
    beforeMount: function () {
        // console.log(this.info);
        this.getInfo(this.info.id)
    },
    methods: {
        ...mapActions('companyUser', ['users', 'updateUser', 'deletUser']),
        ...mapGetters('companyUser', ['getListUsers']),
        ...mapActions('roles', ['roles', 'delRole']),
        ...mapGetters('roles', ['getListRoles']),
        async getInfo(id) {
            await this.users(id)
            this.listUsers = this.getListUsers()
            // console.log(this.listUsers)
        },
        addNew() {
            this.editRows = null
            this.updateRoles()
            this.newData = {
                mail: "",
                role_id: null
            }
        },
        async updateRoles() {
            await this.roles(this.$route.params.id)
            this.listRoles = this.getListRoles()

        },
        async saveNew() {
            let data = {
                'company_id': this.info.id,
                'mail': this.newData.mail,
                'role_id': this.newData.role_id
            }

            // console.log(data);

            await this.updateUser(data)
            this.getInfo(this.info.id)
            this.newData = null;

        },
        deleteNew() {
            this.newData = null;
        },
        edit(indx) {
            this.updateRoles()
            this.newData = null
            this.editRows = indx
            this.editData.id = this.listUsers[indx].id
            this.editData.mail = this.listUsers[indx].mail
            this.editData.roles = this.listUsers[indx].roles[0].id
        },
        isEditeble(indx) {
            return true
        },
        async saveIndex(indx) {
            let dataPram = {
                'company_id': this.info.id,
                'mail': this.editData.mail,
                'role_id': this.editData.roles
            }
            await this.updateUser(dataPram)
            // =============
            this.getInfo(this.info.id)
            this.newData = null
            this.editRows = null
        },
        async deleteRole(id) {
            let data = {
                'company_id': this.info.id,
                'user_id': id,
            }
            await this.deletUser(data)
            this.getInfo(this.info.id)

        }
    }
}
</script>