import axios from 'axios';

const instance = axios.create({
	//baseURL: 'http://kanban-server/',
	baseURL: 'https://cobalt-crm.ru/',
	timeout: 10000
});

export function addResponseHandler(success, error){
	instance.interceptors.response.use(success, error);
}

export default instance;