<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary shadow">
        <div class="container-fluid">
            <span class="navbar-brand mb-0 text-body-secondary ed-text" contenteditable v-html="this.title"
                v-click-outside="onClickOutside" @input="updateParagraph"></span>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                :aria-label="$t('UI.navigationSwitcher')">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end w-100">
                    <li class="nav-item">
                        <button v-if="this.menuTop.user" class="border-0 text-decoration-none me-3 text-body-secondary"
                            @click="$emit('showUser')"><font-awesome-icon :icon="['fas', 'user-group']" /></button>
                        <button v-if="this.menuTop.roles" class="border-0 text-decoration-none me-3 text-body-secondary"
                            @click="$emit('showRole')"><font-awesome-icon :icon="['fas', 'users-gear']" /></button>
                        <button v-if="this.menuTop.task" class="border-0 text-decoration-none me-3 text-body-secondary"
                            @click="$emit('addStatus', 0)"><font-awesome-icon :icon="['fas', 'plus']" /></button>
                        <button class="border-0 text-decoration-none me-3 text-body-secondary"
                            @click="$emit('showDel', 1, 0)"><font-awesome-icon :icon="['fas', 'trash-can']" /></button>
                        <router-link :to="{ name: 'home' }"
                            class="text-decoration-none text-body-secondary"><font-awesome-icon
                                :icon="['fas', 'house']" /></router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
export default {
    data() {
        return {
            content: {
                name: '',
                ed: false
            },
        }
    },
    props: ['title', 'menuTop'],
    directives: {
        clickOutside: vClickOutside.directive
    },

    methods: {
        updateParagraph(event) {
            this.content.name = event.target.innerHTML
            this.content.ed = true
            // this.$emit('updateParagraph', event.target.innerHTML)
        },
        onClickOutside(event) {
            // console.log(this.menuTop)
            if (this.content.ed) {
                this.content.ed = false
                let productName = this.content.name;
                productName = productName.replace(/\<br\>/g, " ");
                this.content.name = productName;
                this.$emit('updateParagraph', this.content.name)
            }
        },

    }
}
</script>