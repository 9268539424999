<template>
    <CobaltHeader />

    <div class="container-fluid dashboard-wrapper">
        <div class="row fill-height">
            <CobaltSidebar />
            <main class="dashboard-content col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <h5 class="dashboard-content-subheader">{{ $t("UI.myCompanies") }}</h5>
                <div class="row align-items-stretch">
                    <div class="col-12 col-md-2 mb-3 align-items-stretch curs-p" @click="newCompany">
                        <div class="card cobalt-card creation-card">
                            <div class="card-body text-center">
                                <h5 class="mb-0">{{ $t("UI.createCompany") }}</h5>
                            </div>
                        </div>
                    </div>                
                    <div class="col-12 col-md-2 mb-3 align-items-stretch" v-for="pr in compLL" :key="pr.id">
                        <router-link :to="{ name: 'company', params: { id: pr.id } }"
                            class="card cobalt-card">
                            <div class="card-body p-0 flex-column" :style="{ 'background-image': 'url(' + require('@/assets/images/dashboard/default_item_background.png') + ')' }">
                                <div class="cobalt-card__header">
                                    <div class="dropdown">
                                        <button class="btn btn-link btn-sm text-body-tertiary" :id="'taskDropdownMenuLink' + pr.id" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="@/assets/images/dashboard/more-vertical.svg">
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu" :aria-labelledby="'taskDropdownMenuLink' + pr.id">
                                            <!-- <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary"><font-awesome-icon :icon="['fas', 'pencil']" /> {{ $t("UI.change") }}</button></li> -->
                                            <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary" @click.stop.prevent="requestDelete($event, pr.id)"><font-awesome-icon :icon="['fas', 'trash']" /> {{ $t("UI.delete") }}</button></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="cobalt-card__body">
                                </div>
                                <div class="cobalt-card__footer">
                                    <div class="cobalt-card__title">{{ pr.title }}</div>
                                </div>                                
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="mt-3"></div>
                
                <h5 class="dashboard-content-subheader">{{ $t("UI.privateProjects") }}</h5>

                <Projects :compInfo="compInf" :routePath="home" :refreshMethod="this.getListComp"/>
            </main>
        </div>
    </div>

    <BssModal :modalID='modalID' ref="BssModal">
        <template v-slot:header>{{ $t("UI.addCompany") }}</template>
        <template v-slot:body>
            <div class="modal-body">
                <form>
                    <div class="mb-3">
                        <label for="exampleInputLogin">{{ $t("UI.name") }}:</label>
                        <input type="text" class="form-control-log" id="exampleInputLogin" aria-describedby="loginHelp"
                            v-model="datComp.title">
                        <small id="loginHelp" class="form-text text-muted"></small>
                    </div>
                    <div class="mb-4">
                        <label for="exampleInputPassword">{{ $t("UI.description") }}:</label>
                        <textarea class="form-control-log" id="exampleInputPassword"
                            v-model="datComp.description"></textarea>
                    </div>

                    <div v-if="datComp.errorText != ''">
                        <p class="mt-2 mb-0 text-danger">{{ datComp.errorText }}</p>
                    </div>
                </form>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn btn-outline-secondary btn-sm me-3"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
                <button type="button" class="btn btn-secondary btn-sm" @click="addCompanyM">{{ $t("UI.add") }}</button>
            </div>
        </template>
    </BssModal>

    <DelModal :modalID='modalDel' modalPosition="center" ref="DelCompanyModalRef">
        <template v-slot:header>{{ $t("UI.addProject") }}</template>
        <template v-slot:body>
            <div class="modal-body">
                <h2>{{ $t("UI.confirmation") }}</h2>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn btn-outline-secondary btn-sm me-3"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
                <button type="button" class="btn btn-danger btn-sm" @click="deleteCompany">{{ $t("UI.delete") }}</button>
            </div>
        </template>
    </DelModal>    
</template>

<script>
import BssModal from "@/components/modals/BsModal.vue";
import DelModal from "@/components/modals/BsModal.vue";
import CobaltHeader from "@/components/dashboard/CobaltHeader.vue";
import CobaltSidebar from "@/components/dashboard/CobaltSidebar.vue";
import Projects from "@/views/project/Projects.vue";
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        BssModal,
        DelModal,
        CobaltHeader,
        CobaltSidebar,
        Projects
    },
    data() {
        return {
            compLL: [],
            compInf: null,
            modalID: 'NewModCompNULP',
            modalDel: 'modalDelete',
            datComp: {
                id: 0,
                title: '',
                description: '',
                errorText: '',
            },
            selectedID: 0
        }
    },
    beforeMount: function () {
        this.getListComp()
    },

    methods: {
        ...mapActions('company', ['list', 'addCompany', 'delete']),
        ...mapGetters('company', ['getList']),
        async getListComp() {
            await this.list([600, 400])
            this.compLL = this.getList()
        },
        newCompany() {
            this.$refs.BssModal.shows()
        },
        requestDelete(event, id) {
            event.stopPropagation();
            event.preventDefault();

            this.selectedID = id
            this.$refs.DelCompanyModalRef.shows()
        },        
        getFileInputValue(event) {
            //get the file input value
            const file = event.target.files;
            //assign it to our reactive reference value 
            this.datComp.image = file[0]
        },  
        async addCompanyM() {
            // console.log(this.datComp);
            await this.addCompany(this.datComp)
            await this.list([600, 400])
            this.compLL = this.getList()
            this.$refs.BssModal.hide()
        },
        async deleteCompany() {
            await this.delete(this.selectedID)
            this.$refs.DelCompanyModalRef.hide()
            this.getListComp()
        }         
    }

}
</script>

<style></style>