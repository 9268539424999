<template>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">{{ $t("UI.roles") }}</th>
                <th scope="col">{{ $t("UI.description") }}</th>
                <th scope="col">{{ $t("UI.accessToProject") }}</th>
                <th scope="col">{{ $t("UI.accessToStatus") }}</th>
                <th scope="col">{{ $t("UI.accessToTask") }}</th>
                <th scope="col">#</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(elemStat, index) in listRoles" :key="elemStat.id">
                <th scope="row"><span v-if="editRows != index || !isEditeble(index)">{{ elemStat.title }}</span>
                    <input class="form-control" v-else type="text" v-model="editData.title">
                </th>
                <td>
                    <span v-if="editRows != index">{{ elemStat.description }}</span>
                    <input class="form-control" v-else type="text" v-model="editData.description">
                </td>
                <td><span v-if="editRows != index || !isEditeble(index)">{{ getStatus(elemStat.project) }}</span>
                    <select class="form-select" v-model="editData.project" v-else>
                        <option value="10">{{ $t("UI.accessRead") }}</option>
                        <option value="11">{{ $t("UI.accessFull") }}</option>
                    </select>
                </td>
                <td><span v-if="editRows != index || !isEditeble(index)">{{ getStatus(elemStat.status) }}</span>
                    <select class="form-select" v-model="editData.status" v-else>
                        <option value="10">{{ $t("UI.accessRead") }}</option>
                        <option value="11">{{ $t("UI.accessFull") }}</option>
                    </select>
                </td>
                <td><span v-if="editRows != index || !isEditeble(index)">{{ getStatus(elemStat.task) }}</span>
                    <select class="form-select" v-model="editData.task" v-else>
                        <option value="10">{{ $t("UI.accessRead") }}</option>
                        <option value="11">{{ $t("UI.accessFull") }}</option>
                    </select>
                </td>
                <td>
                    <div class="d-flex">
                        <button class="border-0 text-decoration-none me-3 text-body-secondary" v-if="editRows != index"
                                :title="$t('UI.change')" @click="edit(index)"><font-awesome-icon
                                :icon="['fas', 'pen-to-square']" /></button>
                        <button class="border-0 text-decoration-none me-3 text-body-secondary" v-else
                            @click="saveIndex(index)" 
                                :title="$t('UI.save')"><font-awesome-icon
                                :icon="['fas', 'floppy-disk']" /></button>
                        <button class="border-0 text-decoration-none me-3 text-body-secondary" v-if="isEditeble(index)"
                            @click="deleteRole(elemStat.id)" 
                                :title="$t('UI.delete')"><font-awesome-icon
                                :icon="['fas', 'trash-can']" /></button>
                    </div>
                </td>

            </tr>
            <tr v-if="newData != null">
                <th scope="row"><input class="form-control" v-model="newData.title"></th>
                <td>
                    <input class="form-control" v-model="newData.description">
                </td>
                <td><select class="form-select" v-model="newData.project">
                        <option value="10">{{ $t("UI.accessRead") }}</option>
                        <option value="11">{{ $t("UI.accessFull") }}</option>
                    </select>
                </td>
                <td><select class="form-select" v-model="newData.status">
                        <option value="10">{{ $t("UI.accessRead") }}</option>
                        <option value="11">{{ $t("UI.accessFull") }}</option>
                    </select>
                </td>
                <td><select class="form-select" v-model="newData.task">
                        <option value="10">{{ $t("UI.accessRead") }}</option>
                        <option value="11">{{ $t("UI.accessFull") }}</option>
                    </select>
                </td>
                <td>
                    <div class="d-flex">
                        <button class="border-0 text-decoration-none me-3 text-body-secondary" @click="saveNew()"
                            :title="$t('UI.save')"><font-awesome-icon :icon="['fas', 'floppy-disk']" /></button>
                        <button class="border-0 text-decoration-none me-3 text-body-secondary" @click="deleteNew()"
                            :title="$t('UI.delete')"><font-awesome-icon :icon="['fas', 'trash-can']" /></button>
                    </div>
                </td>

            </tr>

        </tbody>
    </table>
    <button class="border-0 text-decoration-none me-3 text-body-secondary" @click="addNew()"
        :title="$t('UI.addRole')"><font-awesome-icon :icon="['fas', 'plus']" /></button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vClickOutside from 'click-outside-vue3'
export default {
    props: ['info'],
    data() {
        return {
            listRoles: [],
            editRows: null,
            editData: {
                id: 0,
                title: null,
                description: null,
                project: null,
                status: null,
                task: null
            },
            newData: null,
        }
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    beforeMount: function () {
        // console.log(this.info);
        this.getInfo(this.info.id)
    },
    methods: {
        ...mapActions('roles', ['roles', 'updateRole', 'createRole', 'delRole']),
        ...mapGetters('roles', ['getListRoles']),
        async getInfo(id) {
            await this.roles(this.$route.params.id)
            this.listRoles = this.getListRoles()
        },
        edit(index) {
            this.newData = null
            this.editRows = index
            this.editData.id = this.listRoles[index].id
            this.editData.title = this.listRoles[index].title
            this.editData.description = this.listRoles[index].description
            this.editData.project = this.listRoles[index].project
            this.editData.status = this.listRoles[index].status
            this.editData.task = this.listRoles[index].task
            // console.log(index)
        },
        async saveIndex(index) {
            this.editRows = null
            await this.updateRole(this.editData)
            this.getInfo(this.info.id)
            // console.log(this.editData)
        },
        getStatus(numb) {
            if (numb == 10) {
                return this.$t("UI.accessRead")
            } else if (numb == 11) {
                return this.$t("UI.accessFull")
            }
            return this.$t("UI.accessDenied")
        },
        async deleteRole(id) {
            await this.delRole(id)
            this.getInfo(this.info.id)
            
            this.newData = null
        },
        isEditeble(indx) {
            if (this.listRoles[indx].company > 10) {
                return false
            }
            return true
        },
        addNew() {
            this.editRows = null
            this.newData = {
                company_id: this.info.id,
                title: "",
                description: "",
                project: 10,
                status: 10,
                task: 10
            }
        },
        async saveNew(){
            await this.createRole(this.newData)
            this.getInfo(this.info.id)
            this.newData = null
        },
        deleteNew(id){
            this.newData = null
        },
    }
}
</script>