<template>
    <header :class="['navbar sticky-top navbar-expand-lg flex-md-nowrap p-0', {'collapsed':isCollapsed}]">
        <div class="navbar-brand col-md-3 col-lg-2 me-0 d-flex">
            <div class="navbar-brand_title">
                <span>COBALT</span>
                <span class="navbar-brand_subtitle">For One, For Team, For Corporates</span>
            </div>
            <button class="collapse-switcher" type="button" @click="toggleDashboard">
                <img src="@/assets/images/dashboard/collapse.svg" />
            </button>            
        </div>
        <button class="navbar-toggler position-absolute d-md-none">
            <span class="navbar-toggler-icon"></span>
        </button>
        <nav aria-label="breadcrumb" class="flex-grow-1">
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item" v-if="company || project">
                    <a href="/"><img src="@/assets/images/dashboard/base-home.svg" /></a>
                </li>
                <li class="breadcrumb-item active" aria-current="page" v-if="company">
                    <span><img class="breadcrumb-item-icon" src="@/assets/images/auth/users.svg" />{{ company }}</span>
                </li>
                <li class="breadcrumb-item active" aria-current="page" v-if="project">
                    <span>{{ project }}</span>
                </li>                
            </ol>
        </nav>
        
        <a class="nav-link nav-link-bg cobalt-toolbar__button cobalt-blue-button" @click="this.$emit('toggleInviteModal')"><img src="@/assets/images/dashboard/member-add.svg" /> {{ $t('UI.task.invite') }}</a>
        
        <ul class="navbar-nav me-2">
            <li class="nav-item cobalt-toolbar">
                <a class="nav-link cobalt-toolbar__link" v-if="this.$emit('addUser')" @click="this.$emit('addUser')"><img src="@/assets/images/dashboard/member-add.svg" /></a>
            </li>
            <li class="nav-item dropdown ms-2">
                <a class="cobalt-profile nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    MP
                </a>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                    <li><a class="nav-link px-3" @click="UserOut()">{{ $t("auth.logout") }}</a></li>
                </ul>                
            </li>
        </ul>
    </header>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        props: ['info', 'company', 'project', 'isCollapsed'],
        components: {
        },
        data() {
        },
        beforeMount: function () {
        },
        methods: {
            ...mapActions('user', ['logout']),
            toggleDashboard: function(e){          
                this.$emit('toggle')
            },     
            UserOut(){
                this.logout()
                this.$router.push({ name: 'login' })
            }
        }

    }
</script>

<style></style>