import { createStore } from 'vuex';

import user from './user';
import company from './company';
import companyUser from './companyUser';
import board from './board';
import roles from './roles';
import project from './project';


const store = createStore({
	modules: {
		user,
        company,
		companyUser,
        board,
		roles,
		project,
	},
	strict: process.env.NODE_ENV !== 'production'
});

export default store;