import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index';
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Add SVG Core
import { library } from '@fortawesome/fontawesome-svg-core'

// Free icon
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

// Multilingual
import i18n from "./i18n"

import uploader from 'vue-simple-uploader'
import 'vue-simple-uploader/dist/style.css'

library.add(fas, fab, far)

let app = createApp(App)
    .use(i18n)
    .use(store)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(router)
    .use(uploader);
store.dispatch('user/autoLogin');
app.mount('#page-wrap');

import "./assets/css/main.css";
