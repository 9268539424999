<template>
    <span class="cobalt-profile-small me-1" v-if="this.firstName || this.lastName" :style="{ 'background-color': nameColor(this.firstName) }" >{{ dropCap }}</span> <span v-if="!short">{{ shortName }}</span>
</template>

<script>
    import { nameColor } from '@/utils/format'
    export default {
        props: {
            firstName: {
                type: String,
                default: ''
            },
            lastName: {
                type: String,
                default: ''
            },
            photo: {
                type: String,
                default: ''
            },
            short: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            shortName() {
                return this.firstName && this.lastName ? this.firstName + " " + this.lastName : '';
            },
            dropCap() {
                return (this.firstName ? this.firstName[0] : '') + (this.lastName ? this.lastName[0] : '')
            }
        },
        methods: {
            nameColor
        }
    }
</script>