import http from '@/api/http';

export async function listRoles(token, id) {

    try {
        let dataPram = {
            'company_id': id,
        }

        const response = await http.post('api/v1/role/list',
            dataPram,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function update(token, data) {
    try {
        let dataPram = {
            'title': data.title,
            'description': data.description,
            'id': data.id,
            'project': data.project,
            'status': data.status,
            'task': data.task
        }

        // console.log(dataPram);
        const response = await http.post('api/v1/role/update',
            dataPram,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function create(token, data) {
    try {
        let dataPram = {
            'title': data.title,
            'description': data.description,
            'company_id': data.company_id,
            'project': data.project,
            'status': data.status,
            'task': data.task
        }

        // console.log(dataPram);
        const response = await http.post('api/v1/role/create',
            dataPram,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }

}

export async function gelCompany(token, id) {

    try {

        // console.log(query);

        const response = await http.get('api/v1/role/delete?id=' + id,
            {
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}