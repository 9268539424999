<template>
    <!-- :style="{ 'background-color': task.color != '#000000' ? task.color : null } -->
    <div class="cobalt-task mb-3">
        <div class="cobalt-task__card" @dblclick="showModTask(task.id, statusId)">
            <div class="cobalt-task__card-header d-flex align-items-center justify-content-between">
                <div class="cobalt-task__priority" 
                    :style="{ 
                        'border-color': task.priorityObj.color,
                        'background-color': task.priorityObj.color + 'DD'
                    }
                ">
                    {{ task.priorityObj.label }}
                </div>
                <div class="d-flex align-items-center">
                    <div class="dropdown">
                        <button class="btn btn-link btn-sm text-body-tertiary" :id="'taskDropdownMenuLink' + task.id" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="@/assets/images/dashboard/more-vertical.svg">
                        </button>
                        <ul class="dropdown-menu dropdown-menu" :aria-labelledby="'taskDropdownMenuLink' + task.id">
                            <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary" @click="showModTask(task.id, statusId)"><font-awesome-icon :icon="['fas', 'pencil']" /> {{ $t("UI.task.change") }}</button></li>
                            <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary" @click="showArch(3, task.id)"><font-awesome-icon :icon="['fas', 'box']" /> {{ $t("UI.task.archive") }}</button></li>
                            <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary" @click="showDel(3, task.id)"><font-awesome-icon :icon="['fas', 'trash']" /> {{ $t("UI.task.delete") }}</button></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card-task__card-body">
                <h6 class="cobalt-task__title">{{ task.title }}</h6>
                <div class="cobalt-task__desc">{{ task.description }}</div>
                <div class="cobalt-task__tags"><span class="tag tag-primary">New</span> <span class="tag tag-secondary">UI/UX design</span></div>
                <div class="cobalt-task__progress" v-if="task.progress > 0">progress</div>
                <div class="cobalt-task__performer" v-if="task.performer != ''">
                    <CobaltPerformersList :performers="task.performer" />
                </div>
                <div class="cobalt-task__deadline" v-if="task.date_planned_end > 0">{{ this.$t("UI.task.deadline") + " " + localeDate(task.date_planned_end) }}</div>
            </div>
            <div class="card-task__card-footer">
                <div class="card-task__card-footer-left">
                    <div class="cobalt-task__comments">01</div>
                </div>
                <div class="card-task__card-footer-right">
                    <div class="cobalt-task__done me-2">
                        1/3
                    </div>                    
                    <div class="cobalt-task__attachment">
                        04
                    </div>                    
                </div>
            </div>
        </div>
        <div class="cobalt-task__card-back"></div>
    </div>
</template>

<script>
import { localeDate, priorityOptions, lightenDarkenColor } from '@/utils/format'
import CobaltPerformersList from "@/components/dashboard/parts/CobaltPerformersList.vue"

export default {
    components: {
        CobaltPerformersList
    },
    name: 'TaskCard',
    props: {
        task: {
            type: Object,
            required: true
        },
        statusId: {
            type: Number,
            required: true
        }
    },
    methods: {
        localeDate,
        lightenDarkenColor,
        showModTask(taskId, statusId) {
            this.$emit('showModTask', taskId, statusId);
        },
        showArch(type, id) {
            this.$emit('showArch', type, id);
        },        
        showDel(type, id) {
            this.$emit('showDel', type, id);
        }
    }
}
</script>