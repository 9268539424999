import * as projectApi from '@/api/projectApi.js';

export default {
    namespaced: true,
    state() {
        return {
            projectListDat: [],
            projectListUsers: [],
            projectInfo: null
        }
    },
    getters: {
        getList(state) {
            return state.projectListDat
        },
        getListUsers(state) {
            return state.projectListUsers
        },
        getInfo(state) {
            return state.projectInfo
        }
    },
    mutations: {
        setList(state, listData) {
            state.projectListDat = JSON.parse(listData)
        },
        setListUsers(state, listData) {
            state.projectListUsers = JSON.parse(listData)
        },        
        setInfo(state, listData) {
            state.projectInfo = JSON.parse(listData)
        },
        clearInfo(state) {
            state.projectInfo = null
        },
    },
    actions: {
        async list({ commit }, data) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(data);

                const dataResp = await projectApi.listProject(userData.token, data)

                // console.log(dataResp);

                if (dataResp) {
                    commit('setList', JSON.stringify(dataResp))
                }
            }
        },
        async listUsers({ commit }, id) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await projectApi.listUsers(userData.token, id)

                if (dataResp) {
                    commit('setListUsers', JSON.stringify(dataResp))
                }
            }
        },
        async info({ commit }, id) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(id);

                const dataResp  = await projectApi.getProject(userData.token, id)

                console.log(dataResp);

                if (dataResp) {
                    commit('setInfo', JSON.stringify(dataResp))
                }
            }
        },
        async delete({ commit }, id) {

            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(id);

                const dataResp = await projectApi.delProject(userData.token, id)

                // console.log(dataResp);

                if (dataResp) {
                    commit('clearInfo')
                }
            }
        },
        async addProject({ commit }, data) {
            // console.log(data);
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(id);

                const dataResp = await projectApi.updateProject(userData.token, data)

                // console.log(dataResp);

                if (dataResp) {
                    commit('clearInfo')
                }
            }
        }
    }
}