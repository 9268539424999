import * as userApi from '@/api/companyUserApi.js';

export default {
    namespaced: true,
    state() {
        return {
            companyListUser: [],
        }
    },
    getters: {
        getListUsers(state) {
            return state.companyListUser
        },
    },
    mutations: {
        setListUsers(state, listData) {
            state.companyListUser = JSON.parse(listData)
        },
    },
    actions: {

        async users({ commit }, id) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(id);

                const dataResp = await userApi.listUsers(userData.token, id)

                // console.log(dataResp);

                if (dataResp) {
                    commit('setListUsers', JSON.stringify(dataResp))
                }
            }
        },

        async updateUser({ commit }, data) {
            // console.log(data);
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(id);

                const dataResp = await userApi.updateUser(userData.token, data)

            }
        },

        async deletUser({ commit }, data) {
            // console.log(data);
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(id);

                const dataResp = await userApi.deletUser(userData.token, data)

            }
        },

    }
}