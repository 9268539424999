<template>
    <nav id="sidebarMenu" :class="['d-md-flex sidebar', {'collapsed':isCollapsed}]">
      <div class="position-sticky flex-grow-1 pt-3">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link active d-flex align-items-center" aria-current="page" href="/">
              <img src="@/assets/images/dashboard/base-home.svg" />{{ $t("UI.sidebar.dashboard")}}
            </a>
          </li>
          <li class="nav-item" v-if="companiesCount > 0">
            <a class="nav-link align-items-center" href="#">
              <img src="@/assets/images/dashboard/task-all.svg" />{{ $t("UI.sidebar.companies")}} <span class="tag tag-primary">1</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link align-items-center" href="#">
              <img src="@/assets/images/dashboard/project-all.svg" />{{ $t("UI.sidebar.projects")}}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled align-items-center" href="#">
              <img src="@/assets/images/dashboard/member.svg" />{{ $t("UI.sidebar.team")}}
            </a>
          </li>
          <!--
          <li class="nav-item align-items-center">
            <a class="nav-link" href="#">
              <img src="@/assets/images/dashboard/settings.svg" />{{ $t("UI.sidebar.settings")}}
            </a>
          </li>
          -->
          <li class="nav-item align-items-center">
            <a class="nav-link disabled" href="#">
              <img src="@/assets/images/dashboard/settings.svg" />{{ $t("UI.sidebar.templates")}}
            </a>
          </li>          
          <li class="nav-item align-items-center">
            <a class="nav-link" href="#">
              <img src="@/assets/images/dashboard/message.svg" />{{ $t("UI.sidebar.messages")}}
            </a>
          </li>          
        </ul>

        <h6 class="sidebar-heading d-flex justify-content-between align-items-center mt-4 mb-1">
          <span>My projects</span>
          <a class="link-secondary" href="#">
            <span data-feather="plus-circle"></span>
          </a>
        </h6>
        <ul class="nav flex-column mb-2">
          <li class="nav-item"  v-for="rProject in recentProjects" :key="rProject.id">
            <a class="nav-link nav-link-bg" :href="'/project/' + rProject.id">
              <span data-feather="file-text"></span>
              {{ rProject.name }}
            </a>
          </li>
        </ul>
      </div>
      <div class="pro">
        <span class="image"><img src="@/assets/images/dashboard/idea.png" /></span>
        <h2 class="pro-header">{{ $t('UI.sidebar.toProHeader') }}</h2>
        <h2 class="pro-subheader">{{ $t('UI.sidebar.toProSubHeader') }}</h2>
        <span><button class="btn cobalt-blue-button">{{ $t('UI.sidebar.toProButton') }}</button></span>
      </div>
      <div>
        <LanguageSwitcher />
      </div>
    </nav>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import LanguageSwitcher from '../LanguageSwitcher.vue'
  
  export default {
      components: {
          LanguageSwitcher
      },    
      name: 'CobaltSidebar',
      props: ['isCollapsed', 'active', 'companiesCount'],
      data() {
        return {
          recentProjects: []
        }
      },        
      beforeMount: function () {
        this.loadRecentProjects()
      },        
      methods: {
        ...mapActions('user', ['recent']),
        ...mapGetters('user', ['getRecent']), 
        async loadRecentProjects() {
          this.recentProjects = await this.recent()
        }
      },
  }
</script>