<template>
    <div class="cobalt-profile-list__wrapper mt-2">
        <div class="cobalt-profile-list__item" v-for="item in performers">
            <span class="cobalt-profile cobalt-profile-small me-1" :style="{ 'background-color': nameColor(item.first_name) }" >
                {{ dropCap(item.first_name, item.last_name) }}
            </span>
        </div>             
    </div>    
</template>

<script>
    import { nameColor } from '@/utils/format'
    export default {
        props: {
            performers: {
                type: Array,
                default: []
            },
            short: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            nameColor,
            dropCap(firstName, lastName) {
                return (firstName ? firstName[0] : '') + (lastName ? lastName[0] : '')
            }
        }
    }
</script>