<template>
    <CobaltHeader 
        :info="info" 
        :company="company" 
        :project="project" 
        :isCollapsed="isCollapsed" 
        @toggle="toggle"
        @toggleInviteModal="toggleInviteModal"
    />

    <!-- <Navbar :pojctInfo='pojctInfo' @showMod="showMod" @showDel="showDel" @updateParagraph="updateParagraph"></Navbar> -->
    <!-- <Navbar :title='prInfo.title' :menuTop='menuTop' @showUser="showUser" @addStatus="showMod"
        @showDel="showDel" @showRole="showRole" @updateParagraph="updateParagraph">
    </Navbar> -->

    <div class="container-fluid dashboard-wrapper">
        <div class="row h-100">
            <CobaltSidebar :isCollapsed="isCollapsed" />
            <main class="dashboard-content white col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-0 h-100">            
                <div class="dashboard-content__filters w-100" v-if="withFilterBar">
                    <div class="dashboard-content__filters-panel me-3">
                        <button type="button" class="btn btn-sm me-2 filters-panel__button" @click="toggleDashboardMode('canban')" :class = "{'active' : this.currentMode == 'canban'}">
                            <img src="@/assets/images/dashboard/base-canban.svg" class="me-1"> {{ $t('UI.dashboard.canban') }}
                        </button>   
                        <button type="button" class="btn btn-sm me-2 filters-panel__button" @click="toggleDashboardMode('calendar')" :class = "{'active' : this.currentMode == 'calendar'}">
                            <img src="@/assets/images/dashboard/base-calendar.svg" class="me-1"> {{ $t('UI.dashboard.calendar') }}
                        </button>
                        <button type="button" class="btn btn-sm me-2 filters-panel__button disabled">
                            <img src="@/assets/images/dashboard/base-gantt.svg" class="me-1"> {{ $t('UI.dashboard.gantt') }}
                        </button>                                                                                 
                    </div>   
                    <div class="dashboard-content__filters-panel flex-grow-1 me-3">
                        <div class="ms-1 me-2"><img src="@/assets/images/dashboard/base-filter.svg"></div>
                        <input type="text" class="form-control filters-panel__input" id="filter" :placeholder="$t('UI.dashboard.filter')" disabled />  
                    </div>  
                    <div class="dashboard-content__filters-panel">
                        <div class="ms-1 me-2"><img src="@/assets/images/dashboard/base-sort.svg"></div>
                        <input type="text" class="form-control filters-panel__input" id="sorting" :placeholder="$t('UI.dashboard.sort')" disabled />  
                    </div>    
                    <button v-if="this.currentMode == 'canban'" class="btn btn-lg cobalt-status-column__button ms-3" @click="this.$emit('showMod', 0)">{{ $t("UI.addStatus") }}</button>                                                 
                </div>
                <div :class="'dashboard-content__wrapper container-fluid ' + contentClasses">
                    <slot></slot>
                </div>
            </main>
        </div>
    </div>

    <InviteModal modalID='modalInvite' modalSize="modal-md" modalPosition="center" ref="InviteModal" class="cobalt-base-dialog">
        <template v-slot:header>
            <div class="d-dlex">{{ $t("UI.task.inviteTitle") }}</div>
        </template>
        <template v-slot:body>
            <div class="modal-body ps-0 pe-0">
                <p class="mb-4">{{ $t("UI.task.inviteDescription") }}</p>
                <div class="mb-4">
                    <label for="inviteLinkInput">{{ $t("UI.task.inviteLink") }}</label>
                    <a ref="inviteLinkRef" class="form-control cobalt-copy-button" id="inviteLinkInput" aria-describedby="inviteLinkHelp" @click="copy">
                        https://cobalt-crm.ru/invite/code=sadflsdhfLKJHLKJH8768976575ggg
                    </a>
                    <small id="inviteLinkHelp" class="form-text text-muted"></small>
                </div>                
            </div>
            <div class="modal-footer modal-footer p-0 pt-3 pb-3">
                <button type="button" class="btn btn-outline-secondary btn-sm m-0 me-2"
                    data-bs-dismiss="modal">{{ $t("UI.cancel") }}
                </button>
            </div>
        </template>
    </InviteModal>  

</template>

<script>
import CobaltHeader from "@/components/dashboard/CobaltHeader.vue";
import CobaltSidebar from "@/components/dashboard/CobaltSidebar.vue";
import InviteModal from "@/components/modals/BsModal.vue"
import { createToast } from 'mosha-vue-toastify';   
import 'mosha-vue-toastify/dist/style.css'
import { ref } from 'vue'

export default {
    components: {
        CobaltHeader,
        CobaltSidebar,
        InviteModal     
    },
    props: ['contentClasses', 'info', 'company', 'project', 'withFilterBar'],    
    data() {
        return {
            currentMode: 'canban', // default mode
            isCollapsed: false
        }
    },
    methods: {
        toggle(mode) {
            //
        },
        toggleInviteModal() {
            this.$refs.InviteModal.shows()            
        },
        toggleDashboardMode(mode) {
            this.currentMode = mode
            this.$emit('switchMode', mode)
        },
        async copy(event) {
            try {
                await navigator.clipboard.writeText(event.target.textContent);
                createToast({ description: this.$t("UI.linkCopied") }, {
                    hideProgressBar: true,
                    timeout: 1000,
                    position: 'bottom-center',
                    transition: 'slide',
                    showIcon: false,
                    showCloseButton: false,
                    swipeClose: false,
                })
            } catch($e) {
                alert('Cannot copy');
            }
        }              
    }
}
</script>
<style>
    .mosha__toast {
        justify-content:center;
    }
    .mosha__toast.default {
        background-color: #142941;
        max-height: 48px;
        min-height: 36px;
    }  
    .mosha__toast.default .mosha__toast__content {
        color: #FFF;
    }    
    .mosha__toast__content-wrapper {
        max-height: 48px;
    }
</style>