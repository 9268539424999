import http from '@/api/http';

export async function listUsers(token, id) {

    try {
        const response = await http.get('api/v1/user/company?id=' + id,
            {
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function updateUser(token, data) {

    try {
        let dataPram = {
            'company_id': data.company_id,
            'mail': data.mail,
            'role_id': data.role_id
        }

        // console.log(dataPram);
        const response = await http.post('api/v1/role/add',
            dataPram,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function deletUser(token, data) {

    try {
        let dataPram = {
            'company_id': data.company_id,
            'user_id': data.user_id
        }

        // console.log(dataPram);
        const response = await http.post('api/v1/role/del-company',
            dataPram,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}
