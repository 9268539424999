import http from '@/api/http';

export async function listCompany(token) {

    try {

        // console.log(query);

        const response = await http.get('api/v1/company/list',
            {
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function getCompany(token, id) {

    try {

        // console.log(query);

        const response = await http.get('api/v1/company?id=' + id,
            {
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}


export async function updateCompany(token, data) {
    try {
        let dataPram = {
            'title': data.title,
            'description': data.description,
        }
        if (data.id > 0) {
            dataPram['id'] = data.id
        }

        // console.log(dataPram);
        const response = await http.post('api/v1/company',
            dataPram,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function delCompany(token, id) {
    try {
        const response = await http.delete('api/v1/company?id=' + id, {
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
        })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}