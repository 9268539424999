<template>
    <div>
        <h1>Page not found</h1>
        <hr>
        Start from main page
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {

}
</script>