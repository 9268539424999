import http from '@/api/http';

export async function login(login, password) {
    try {
        const response = await http.get('api/v1/auth', { headers: { 'Authorization': 'Basic ' + btoa(login + ":" + password), 'Content-Type': 'application/json', } })
        // commit('SET_USER_DATA', response.data)
        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function register(data) {
    try {
        let dataPram = {
            'username': data.username,
            'first_name': data.first_name,
            'last_name': data.last_name,
            'mail': data.mail,
            'password': data.password,
        }
        const response = await http.post('api/v1/register',
            dataPram,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
        // commit('SET_USER_DATA', response.data)
        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function activate(key) {
    try {
        const response = await http.get('api/v1/register/new?key=' + key, {
            headers: { 'Content-Type': 'application/json', }
        })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function setData(token) {
    try {
        const response = await http.get('api/v1/user', {
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
        })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function recentProjects(token) {
    try {
        const response = await http.delete('api/v1/user/open-project', {
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
        })

        return response.data
    } catch (error) {
        console.log(error.response)
        return null
    }
}
