import * as boardApi from '@/api/boardApi';


export default {
    namespaced: true,
    state() {
        return {
            listStatus: [],
        }
    },
    getters: {
        getList(state) {
            return state.listStatus
        },
    },
    mutations: {
        setList(state, listData) {
            state.listStatus = JSON.parse(listData)
        },
    },
    actions: {
        async list({ commit }, projectId) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.listSt(userData.token, projectId)

                if (dataResp) {
                    commit('setList', JSON.stringify(dataResp))
                }
            }
        },
        async updatSt({ commit }, data) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.updateStatus(userData.token, data)
            }
        },
        async delSt({ commit }, id) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.delStatus(userData.token, id)

            }
        },
        async updatTsk({ commit }, data) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.updateTask(userData.token, data)

                return dataResp
            }

            return false;
        },
        async updatTskDate({ commit }, data) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.updateTaskDate(userData.token, data)
            }
        },        
        async clearTskDate({ commit }, data) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.clearTaskDate(userData.token, data)
            }
        },            
        async delTsk({ commit }, id) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.delTask(userData.token, id)
            }
        },
        async archTsk({ commit }, id) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.archTask(userData.token, id)
            }
        },        
        async listPerformers({commit}, data) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.listPerformers(userData.token, data)
            }            
        },        
        async assignPerformer({commit}, data) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.assignPerformer(userData.token, data)
            }            
        },
        async detachPerformer({commit}, data) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.detachPerformer(userData.token, data)
            }            
        }, 
        async uploadFiles({commit}, data) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.uploadFiles(userData.token, data)
            }            
        },                
        async sortAll({ commit }, data) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await boardApi.sortAll(userData.token, data)
            }
        },

    }
}