import http from '@/api/http';

export async function listProject(token, data) {

    // console.log(data);
    let query = "";
    let qq = "?"
    if (data.pic_h > 0) {
        query += "?pic_h=" + data.pic_h
        qq = "&"
    }
    if (data.pic_w > 0) {
        query += qq +"pic_w=" + data.pic_w
        qq = "&"
    }
    if (data.company_id > 0) {
        query += qq + "company_id=" + data.company_id
    }

    // console.log(query);

    try {

        // console.log(query);

        const response = await http.get('api/v1/project/list' + query,
            {
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function listUsers(token, id) {
    try {
        const response = await http.get('api/v1/user/project?id=' + id,
            {
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }    
}

export async function getProject(token, id, width) {
    let query = "";
    // if (width) {
    //     query = "&pic_h=" + width[1] + "&pic_w=" + width[0];
    // }

    try {

        // console.log(query);

        const response = await http.get('api/v1/project?id=' + id + query,
            {
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function updateProject(token, data) {
    try {
        let dataPram = {
            'name': data.name,
            'description': data.description,
        }
        if (data.id > 0) {
            dataPram['id'] = data.id
        } else
        {
            dataPram['company_id'] = data.company_id
        }
        if (data.image) {
            dataPram['pic'] = data.image
        }
        console.log(dataPram);
        const response = await http.post('api/v1/project',
            dataPram,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function delProject(token, id) {
    try {
        const response = await http.delete('api/v1/project?id=' + id, {
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
        })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}