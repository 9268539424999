import http from '@/api/http';
import { serialize } from '@/utils/format';

export async function listSt(token, projectId) {
    try {

        // console.log(query);

        const response = await http.get('api/v1/status?project=' + projectId,
            {
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function updateStatus(token, data) {
    try {
        let dataPram = {
            'title': data.title,
            'description': data.description,
            'color': data.color
        }
        if (data.id > 0) {
            dataPram['id'] = data.id
        } else {
            dataPram['project'] = data.project_id
        }

        // console.log(dataPram);
        const response = await http.post('api/v1/status',
            dataPram,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function delStatus(token, id) {
    try {
        const response = await http.delete('api/v1/status?id=' + id, {
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
        })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function updateTask(token, data) {
    try {
        let dataPram = {
            'title': data.title,
            'description': data.description,
            'color': data.color,
            'status': data.status_id,
            'priority': data.priority
        }
        if (data.id > 0) {
            dataPram['id'] = data.id
        }

        // console.log(dataPram);
        const response = await http.post('api/v1/task',
            dataPram,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function updateTaskDate(token, data) {
    try {
        let dataPram = {}

        if (data.date_planned_start) {
            dataPram['start'] = data.date_planned_start
        }

        if (data.date_planned_end) {
            dataPram['end'] = data.date_planned_end
        }

        if (data.id > 0) {
            dataPram['id'] = data.id
        }

        // console.log(dataPram);
        const response = await http.get('api/v1/task/planned-date?' + serialize(dataPram), {
            headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data', }
        })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function clearTaskDate(token, data) {
    try {
        let dataPram = {}

        if (!data.date_planned_start) {
            dataPram['start'] = 1
        }

        if (!data.date_planned_end) {
            dataPram['end'] = 1
        }

        if (data.id > 0) {
            dataPram['id'] = data.id
        }

        // console.log(dataPram);
        const response = await http.delete('api/v1/task/planned-date?' + serialize(dataPram), {
            headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data', }
        })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function delTask(token, id) {
    try {
        const response = await http.delete('api/v1/task?id=' + id, {
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
        })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function archTask(token, id) {
    try {
        const response = await http.delete('api/v1/task?id=' + id, {
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
        })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function listPerformers(token, taskId) {
    try {

        // console.log(query);

        const response = await http.get('api/v1/contractor',
            {
                'id': taskId,
                'subtask': false
            },
            {
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}

export async function assignPerformer(token, data) {
    try {
        let dataPram = {
            'id': data.task_id,
            'subtask': data.subtask,
            'user': data.performer_id
        }

        const response = await http.post('api/v1/contractor', 
            dataPram,
            {
                headers: { 
                    'Authorization': 'Bearer ' + token, 
                    'Content-Type': 'multipart/form-data'
                }
            })

        return response.data
    } catch (error) {
        console.log(error.response)
        return null        
    }
}

export async function detachPerformer(token, data) {
    try {
        let dataPram = {
            'id': data.task_id,
            'subtask': data.subtask,
            'user': data.performer_id
        }

        const response = await http.get('api/v1/contractor/delete?' + serialize(dataPram), 
            {
                headers: { 
                    'Authorization': 'Bearer ' + token, 
                    'Content-Type': 'application/json'
                }
            })

        return response.data
    } catch (error) {
        console.log(error.response)
        return null        
    }
}

export async function uploadFiles(token, data) {
    try {
        for (var pair of data.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }     
        const response = await http.post('api/v1/task/file', data,
            {
                headers: { 
                    'Authorization': 'Bearer ' + token, 
                    'Content-Type': 'multipart/form-data'
                }
            })

        return response.data
    } catch (error) {
        console.log(error.response)
        return null        
    }
}

export async function sortAll(token, data) {
    try {
        let dataPram = {
            'ids': data
        }

        // console.log(dataPram);
        const response = await http.post('api/v1/status/sort',
            dataPram,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            })

        return response.data

    } catch (error) {
        console.log(error.response)
        return null
    }
}