<template>
    <div>
      <label>{{ $t("UI.task.performer") }}: </label>
      <multiselect
        v-model="selectedUsers"
        :options="filteredUsers"
        :multiple="true"
        :searchable="true"
        :loading="isLoading"
        @search-change="applyFilter"
        placeholder="Начните вводить имя пользователя"
        label="name"
        track-by="id"
      />
    </div>
  </template>
  
  <script>
  import Multiselect from 'vue-multiselect';
 
  export default {
    name: 'UserSelect',
    components: { Multiselect },
    props: {
      value: {
        type: Array,
        default: () => []
      },
      projectId: {
        type: [String, Number],
        required: true
      }
    },
    data() {
      return {
        users: [],
        filteredUsers: [],
        selectedUsers: this.value,
        isLoading: false,
      };
    },
    watch: {
      selectedUsers(newValue) {
        this.$emit('input', newValue);
      },
      projectId: 'fetchUsers'
    },
    created() {
      this.fetchUsers();
    },
    methods: {
      fetchUsers() {
        if (!this.projectId) return;
        this.isLoading = true;
        // Замените URL на реальный адрес вашего API
       const response = getProjectUsers(token, this.projectId)
          .then(response => response.json())
          .then(data => {
            this.users = data;
            this.filteredUsers = data;
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      applyFilter(search) {
        if (search.length < 3) {
          this.filteredUsers = this.users;
          return;
        }
        this.filteredUsers = this.users.filter(user =>
          user.name.toLowerCase().includes(search.toLowerCase())
        );
      }
    }
  };
  </script>

  <style src="vue-multiselect/dist/vue-multiselect.css"></style>
  