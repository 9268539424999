<template>
  <div class="d-lg-flex half">
    <div 
        class="bg order-1 order-md-2 auth-bg d-none d-lg-block" 
        :style="{'background-image': 'url(' + require('@/assets/images/auth/' + $i18n.locale +  '/welcome.svg') + ')'}"
    >
    </div>
    <div class="contents order-2 order-md-1">

        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-md-7 text-center">  

                    <div class="cobalt-auth mb-4 text-lg-center">
                        <img :src="require('@/assets/images/auth/' + $i18n.locale +  '/registration.svg')" :alt="$t('auth.registration')" />
                    </div>
                    <p class="cobalt-auth cobalt_color-halfblack mb-4 text-lg-center" style="white-space: pre">
                        {{ $t("auth.welcome") }}
                    </p>
                    <form class="d-flex flex-column align-items-center">
                        <div class="form-group cobalt-form-group d-flex justify-content-center has-feedback mb-3" v-if="!activ">
                            <div class="cobalt-input-group">
                                <span class="input-group-text">
                                    <img src="@/assets/images/auth/name.svg" />
                                </span>
                                <input type="text" class="form-control cobalt-flat-input cobalt-input-auth" :placeholder="$t('auth.username')"
                                    id="inputUsername22" aria-describedby="usernameHelp" v-model="authData.username" required>                                    
                            </div> 
                            <small id="usernameHelp" class="form-text text-muted"></small>
                        </div>
                        <div class="form-group cobalt-form-group d-flex justify-content-center mb-3" v-else>
                            <div class="cobalt-input-group">
                                <span class="input-group-text">
                                    <img src="@/assets/images/auth/username.svg" />
                                </span>                            
                                <input type="text" class="form-control cobalt-flat-input cobalt-input-auth" :placeholder="$t('auth.activationCode')"
                                    id="inputActiveCode" aria-describedby="activeCodeHelp" v-model="activCode" required>
                            </div>
                            <small id="inputActiveCode" class="form-text text-muted"></small>
                        </div>
                        <div class="form-group cobalt-form-group d-flex justify-content-center mb-3" v-if="!activ">
                            <div class="cobalt-input-group">
                                <span class="input-group-text">
                                    <img src="@/assets/images/auth/name.svg" />
                                </span>                            
                                <input type="text" class="form-control cobalt-flat-input cobalt-input-auth" :placeholder="$t('auth.firstname')"
                                    id="inputFName" aria-describedby="fNameHelp" v-model="authData.first_name" required>
                            </div>
                            <small id="fNameHelp" class="form-text text-muted"></small>
                        </div>
                        <div class="form-group cobalt-form-group d-flex justify-content-center mb-3" v-if="!activ">
                            <div class="cobalt-input-group">
                                <span class="input-group-text">
                                    <img src="@/assets/images/auth/name.svg" />
                                </span>                            
                                <input type="text" class="form-control cobalt-flat-input cobalt-input-auth" :placeholder="$t('auth.surname')"
                                    id="inputLName" aria-describedby="lNameHelp" v-model="authData.last_name" required>
                            </div>
                            <small id="lNameHelp" class="form-text text-muted"></small>
                        </div>
                        <div class="form-group cobalt-form-group d-flex justify-content-center mb-3" v-if="!activ">
                            <div class="cobalt-input-group">
                                <span class="input-group-text">
                                    <img src="@/assets/images/auth/email.svg" />
                                </span>                            
                                <input type="text" class="form-control cobalt-flat-input cobalt-input-auth" :placeholder="$t('auth.email')"
                                    id="inputEmail" aria-describedby="emailHelp" v-model="authData.mail" required>
                            </div>
                            <small id="emailHelp" class="form-text text-muted"></small>
                        </div>
                        <div class="form-group cobalt-form-group d-flex justify-content-center mb-3" v-if="!activ">
                            <div class="cobalt-input-group">
                                <span class="input-group-text">
                                    <img src="@/assets/images/auth/password.svg" />
                                </span>                            
                                <input type="password" class="form-control cobalt-flat-input cobalt-input-auth" :placeholder="$t('auth.password')"
                                    id="inputPassword" v-model="authData.password" required>
                            </div>
                        </div>
                        <div class="form-group cobalt-form-group d-flex justify-content-center mb-5" v-if="!activ">
                            <div class="cobalt-input-group">
                                <span class="input-group-text">
                                    <img src="@/assets/images/auth/password.svg" />
                                </span>                            
                                <input type="password" class="form-control cobalt-flat-input cobalt-input-auth" :placeholder="$t('auth.passwordRetype')"
                                    id="inputRetypePassword" v-model="authData.passwordRetype" required ref="repeatedPasswordEl">
                            </div>
                        </div>                        
                        <div class="cobalt-form-group d-flex">
                            <button type="button" class="btn cobalt-btn-primary btn-lg flex-fill" v-if="!activ"
                                @click="tryRegister">{{ $t("auth.registration") }}</button>
                            <button type="button" class="btn cobalt-btn-primary btn-lg flex-fill ms-3" v-else
                                @click="tryActive">{{ $t("auth.activation") }}</button>
                            <button type="button" class="btn cobalt-btn-link btn-lg flex-fill ms-3" @click="tryLogin">{{ $t("auth.enter") }}</button>
                        </div>
                        <div v-if="authData.errorText != ''">
                            <p class="mt-2 mb-0 text-danger">{{ authData.errorText }}</p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div> 
  </div>
</template>

<script>
import { activate } from '@/api/userApi';
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {},
    data() {
        return {
            authData: {
                username: '',
                first_name: '',
                last_name: '',
                mail: '',
                password: '',
                passwordRetype: '',
                errorText: ''
            },
            activCode: '',
            activ: false,
            title: 'Регистрация',
        }
    },
    computed: {

    },
    methods: {
        ...mapActions('user', ['login', 'register', 'activate']),
        checkPasswordsEquality() {
            const { password, passwordRetype } = this.authData;
            const { repeatedPasswordEl } = this.$refs;

            if (password !== repeatedPassword) {
                repeatedPasswordEl.setCustomValidity(
                'Пароли должны совпадать',
                );
            } else {
                repeatedPasswordEl.setCustomValidity('');
            }
        },        
        async tryRegister() {
            if (this.authData.password != this.authData.passwordRetype) {
                this.authData.errorText = this.$t("error.wrongPasswordRetype");
                return;
            }

            let register = await this.register(this.authData);
            console.log(register);

            if (register) {
                this.activ = true
                this.title = this.$t("auth.activation")
            }
            else {
                this.authData.errorText = this.$t("error.wrongData");
            }
        },
        tryLogin() {
            this.$router.push({ name: 'login' })
        },
        async tryActive() {
            let act = await this.activate(this.activCode);
            if (act) {
                this.$router.push({ name: 'home' })
            }
            else {

                this.authData.errorText = this.$t("error.activationFailed");
                this.activ = false
                this.title = this.$t("auth.registration")
            }
        }
    }
}
</script>

<style>
.btn-log {
    --bs-btn-color: #fff !important;
    --bs-btn-bg: #065588 !important;
    --bs-btn-border-color: #065588 !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: #054068 !important;
    --bs-btn-hover-border-color: #054068 !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #054068;
    --bs-btn-active-border-color: #054068;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #065588;
    --bs-btn-disabled-border-color: #065588;
}

</style>