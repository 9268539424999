export function formatDate(date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const d = new Date(date)
    return d.toLocaleDateString(undefined, options) + " " + d.toLocaleTimeString(undefined, options);
}
export function localeDate(timestamp) {
    if (timestamp) {
      const d = new Date(timestamp * 1000)
      return d.toLocaleDateString('ru-RU') + " " + d.toLocaleTimeString('ru-RU', {hour: '2-digit', minute:'2-digit'}); 
    } else {
      return "";
    }
}
export function serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}
export function priorityOptions() {
  return [
    {id: 0, color: "#555555"},
    {id: 1, color: "#e62200"},
    {id: 2, color: "#ffd000"},
    {id: 3, color: "#91e600"},
    {id: 4, color: "#0086e6"}
  ]
}
export function lightenDarkenColor(col, amt) {
  var usePound = false;

  if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
  }

  var num = parseInt(col,16);
  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0; var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0; var g = (num & 0x0000FF) + amt; if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}
export function nameColor( name ) {
  var hash = 0;
  if (name.length === 0) return '#000';
  for (var i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
  }
  var color = '#';
  for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 16)) & 255;
      color += ('00' + value.toString(16)).slice(-2);
  }
  return color;
}
export function eventDate(timestamp) {

  var date = new Date(timestamp * 1000);
  var aaaa = date.getUTCFullYear();
  var gg = date.getUTCDate();
  var mm = (date.getUTCMonth() + 1);

  if (gg < 10)
      gg = "0" + gg;

  if (mm < 10)
      mm = "0" + mm;

  var cur_day = aaaa + "-" + mm + "-" + gg;

  var hours = date.getUTCHours()
  var minutes = date.getUTCMinutes()

  if (hours < 10)
      hours = "0" + hours;

  if (minutes < 10)
      minutes = "0" + minutes;

  return cur_day + " " + hours + ":" + minutes;

}