<template>
    <div class="text-center m-2">
        <button class="btn btn-sm" @click="changeLanguage('en')" style="transform: scale(0.8);">
            <img src="@/assets/images/dashboard/en.png" />
        </button>
        <button class="btn btn-sm" @click="changeLanguage('ru')" style="transform: scale(0.8);">
            <img src="@/assets/images/dashboard/ru.png" />
        </button>
    </div>
  </template>
  
<script>
import { useI18n } from 'vue-i18n';

export default {
    setup() {
        const { locale } = useI18n();

        const changeLanguage = (lang) => {
            locale.value = lang;
            localStorage.setItem('lang', lang);

            window.location.reload(); // временное решение, не обновляются приоритеты, реактивными нужно сделать
        };

        return {
            changeLanguage,
        };
    },
};


</script>